import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeDate',
})
export class ActiveDatePipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return [];
        }

        return items.filter(((day) => day.date === filter));
    }
}
