import { Component } from '@angular/core';
import { fadeIn } from '@shared/animations';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [ fadeIn ],
})
export class AccordionComponent {
  isTermsAndConditionsOpen: boolean = true;
  isFAQOpen: boolean = false;
}
