import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stationName'
})
export class StationNamePipe implements PipeTransform {

  transform(value: string): string {
    let finalValue = value;
    switch (value) {
      case 'BHM':
        finalValue = 'Birmingham New Street'
        break;
    
      case 'GLC':
        finalValue = 'Glasgow Central'
        break;
    
      case 'LIV':
        finalValue = 'Liverpool Lime Street'
        break;
    
      case 'EUS':
        finalValue = 'London Euston'
        break;
    
      case 'MAN':
        finalValue = 'Manchester Piccadilly'
        break;
      
      case 'PRE':
        finalValue = 'Preston (Lancs)'
        break;
    }
    
    return finalValue;
  }

}
