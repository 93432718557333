import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  newsletterForm: FormGroup = new FormGroup({});

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,) { }

  get email() { return this.newsletterForm.controls.email; }

  ngOnInit(): void {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  subscribe() {
    if (this.newsletterForm.invalid) {
      return;
    }

    this.apiService.newsletter(this.email.value).subscribe(res => {
    });
  }
}
