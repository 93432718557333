import { CheckoutRequest, isReturnProduct, isSingleProduct } from '@core/types/checkout';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ReturnSearchResponse, SingleSearchResponse } from '@core/types/search';
import { searchAvailableTripsFailure, searchAvailableTripsWithReturnFailure } from '@core/store/store.actions';

import { AppState } from '@core/store/store.state';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Router } from '@angular/router';
import { RoutingEnum } from '@shared/enums/navigation.enum';
import { Store } from '@ngrx/store';
import { catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { getTrackingParamsAsQueryString } from 'src/tracking';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = environment.BASE_API_URL;
  private stationsUrl = environment.STATIONS_API_URL;
  private flexUrl = environment.FLEX_API_URL;
  private clientCode = environment.CLIENT_CODE;
  private stationsClientCode = "SecretfareWeb";

  // Load these once and reuse them even once we've navigated and the query string is no longer in the URL
  private trackingParameters = getTrackingParamsAsQueryString();

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private store$: Store<AppState>,
  ) { }

  public fetchStationsList(): Observable<any> {
    let url = this.stationsUrl + "/flex/{partnerCode}";
    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          return throwError(errorMsg);
      })
    );
  }

  public fetchDestinationStations(originCode: string) {
    let url = this.stationsUrl + "/flex/{partnerCode}?origin={originCode}";

    if (originCode === undefined || originCode === null) {
      const msg = 'The parameter \'originCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace("{originCode}", encodeURIComponent("" + originCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
    .get(url)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg: string = this.getServerErrorMessage(error);
        this.messageService.showErrorToast(errorMsg);
        return throwError(errorMsg);
    })
  );
  }

  public fetchOriginStations(destinationCode: string) {
    let url = this.stationsUrl + "/flex/{partnerCode}?destination={destinationCode}";

    if (destinationCode === undefined || destinationCode === null) {
      const msg = 'The parameter \'destinationCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace("{destinationCode}", encodeURIComponent("" + destinationCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
    .get(url)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg: string = this.getServerErrorMessage(error);
        this.messageService.showErrorToast(errorMsg);
        return throwError(errorMsg);
    })
  );
  }

  public searchForDepartures(
    date: string,
    origin: string,
    destination: string,
    numberOfTravellers: number
  ): Observable<any> {
    const partnerCode = this.clientCode;
    let url = this.flexUrl + "/{partnerCode}/search/{date}/{origin}/{destination}?numPassengers={numberOfTravellers}";

    if (this.trackingParameters) {
        url += `&${this.trackingParameters}`;
    }

    if (date === undefined || date === null) {
      const msg = 'The parameter \'date\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (origin === undefined || origin === null) {
      const msg = 'The parameter \'origin\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (destination === undefined || destination === null) {
      const msg = 'The parameter \'destination\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (numberOfTravellers === undefined || numberOfTravellers === null) {
      const msg = 'The parameter \'numberOfTravellers\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (partnerCode === undefined || partnerCode === null) {
      const msg = 'The parameter \'partnerCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{date}", encodeURIComponent("" + date));
    url = url.replace("{origin}", encodeURIComponent("" + origin));
    url = url.replace("{destination}", encodeURIComponent("" + destination));
    url = url.replace("{numberOfTravellers}", encodeURIComponent("" + numberOfTravellers));
    url = url.replace("{partnerCode}", encodeURIComponent("" + partnerCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          this.store$.dispatch(searchAvailableTripsFailure({errors: errorMsg}))
          return throwError(errorMsg);
      })
    );
  }

  public searchForDeparturesWithReturn(
    date: string,
    origin: string,
    destination: string,
    numberOfTravellers: number,
    inboundDate: string,
  ): Observable<any> {
    const partnerCode = this.clientCode;
    let url = this.flexUrl + "/{partnerCode}/search/{date}/{origin}/{destination}/returning/{inboundDate}?numPassengers={numberOfTravellers}";

    if (this.trackingParameters) {
      url += `&${this.trackingParameters}`;
    }

    if (date === undefined || date === null) {
      const msg = 'The parameter \'date\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (origin === undefined || origin === null) {
      const msg = 'The parameter \'origin\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (destination === undefined || destination === null) {
      const msg = 'The parameter \'destination\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (numberOfTravellers === undefined || numberOfTravellers === null) {
      const msg = 'The parameter \'numberOfTravellers\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (partnerCode === undefined || partnerCode === null) {
      const msg = 'The parameter \'partnerCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (inboundDate === undefined || inboundDate === null) {
      const msg = 'The parameter \'inboundDate\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{date}", encodeURIComponent("" + date));
    url = url.replace("{origin}", encodeURIComponent("" + origin));
    url = url.replace("{destination}", encodeURIComponent("" + destination));
    url = url.replace("{numberOfTravellers}", encodeURIComponent("" + numberOfTravellers));
    url = url.replace("{partnerCode}", encodeURIComponent("" + partnerCode));
    url = url.replace("{inboundDate}", encodeURIComponent("" + inboundDate));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          this.store$.dispatch(searchAvailableTripsWithReturnFailure({errors: errorMsg}))
          return throwError(errorMsg);
      })
    );
  }

  public initCheckoutSession(session: string, request: CheckoutRequest) {
    if (
      (isReturnProduct(request.buy) && (request.buy.outbound.timeband === null || request.buy.inbound.timeband === null))
      || (isSingleProduct(request.buy) && request.buy.timeband === null)
    ) {
      const msg = 'Timeband cannot be null.'
      return throwError(msg);
    }

    const partnerCode = this.clientCode;
    let url = this.flexUrl + "/{partnerCode}/{session}/checkout";

    if (this.trackingParameters) {
      url += `?${this.trackingParameters}`;
    }

    let body = request;

    if (session === undefined || session === null) {
      const msg = 'The parameter \'session\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (partnerCode === undefined || partnerCode === null) {
      const msg = 'The parameter \'partnerCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (request === undefined || request === null) {
      const msg = 'The parameter \'request\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{session}", encodeURIComponent("" + session));
    url = url.replace("{partnerCode}", encodeURIComponent("" + partnerCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .post<any>(url, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast('something went wrong. check the form details');
          this.router.navigate([RoutingEnum.Booking])
          return throwError(errorMsg);
        })
      );
  }

  public newsletter(email: string) {
    let url = this.baseUrl + "/newsletter/subscribe";

    if (email === undefined || email === null) {
      const msg = 'The parameter \'email\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    let body = { email };

    url = url.replace("{email}", encodeURIComponent("" + email));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .post<any>(url, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          return throwError(errorMsg);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
          return `Not Found: ${error.error.message}`;
      }
      case 403: {
          return `Access Denied: ${error.error.message}`;
      }
      case 500: {
          return `Internal Server Error: ${error.error.message}`;
      }
      default: {
          return `Unknown Server Error: ${error.error.message}`;
      }
    }
  }
}
