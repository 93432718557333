import { AvailableTimeband, ReturnSearchResponse, SearchResponse, SingleSearchResponse } from "@core/types/search";
import { CheckoutRequest, CheckoutResponse } from "@core/types/checkout";
import { createAction, props } from "@ngrx/store";

import { Price } from "@core/types/core";
import { Station } from "@core/types/stations";
import { TripData } from "@core/types/data";
import { TripTypeEnum } from "@shared/enums/calendarEnum";

// Fetch stations list
export const getStationsList = createAction('[App] Get station list');
export const getStationsListSuccess = createAction('[App] Get station list success', props<{ data: any }>());
export const getStationsListFailure = createAction('[App] Get station list failure', props<{ errors: any }>());

// Fetch calendar template
export const searchAvailableTrips = createAction('[App] Search available trips', props<{ date: string, origin: string, destination: string, numberOfTravellers: number, tripData: TripData }>());
export const searchAvailableTripsSuccess = createAction('[App] Search available trips success', props<{ data: SingleSearchResponse, tripData: TripData }>());
export const searchAvailableTripsFailure = createAction('[App] Search available trips failure', props<{ errors: any }>());

// Fetch calendar template witch returning
export const searchAvailableTripsWithReturn = createAction('[App] Search available trips with return', props<{ date: string, origin: string, destination: string, numberOfTravellers: number, inboundDate: string, tripData: TripData }>());
export const searchAvailableTripsWithReturnSuccess = createAction('[App] Search available trips with return success', props<{ data: ReturnSearchResponse, tripData: TripData }>());
export const searchAvailableTripsWithReturnFailure = createAction('[App] Search available trips with return failure', props<{ errors: any }>());

// Init payment session
export const initCheckoutSession = createAction('[App] Init checkout session', props<{ data: { session: string; request: CheckoutRequest } }>());
export const initCheckoutSessionSuccess = createAction('[App] Init checkout session success', props<{ data: CheckoutResponse }>());
export const initCheckoutSessionFailure = createAction('[App] Init checkout session failure', props<{ errors: any }>());

export const setStationsList = createAction('[App] Set stations list', props<{ stations: Station[] }>());

export const setActiveTripTemplate = createAction('[App] Set active trip template', props<{ activeTrip: SearchResponse }>());

export const setActiveTripData = createAction('[App] Set active trip data', props<{ tripData: TripData }>());

export const clearStationsList = createAction('[App] Clear stations list');

export const clearActiveTrip = createAction('[App] Clear active trip');

export const updateTripDate = createAction('[App] Update trip date', props<{ date: string }>());

export const updateReturnTripDate = createAction('[App] Update return trip date', props<{ date: string }>());

export const setActiveTripTimeband = createAction('[App] Set active trip timeband', props<{ timeband: AvailableTimeband, durations: Record<string, string> }>());

export const clearActiveTripTimeband = createAction('[App] Clear active trip timeband');

export const setActiveReturnTripTimeband = createAction('[App] Set active return trip timeband', props<{ timeband: AvailableTimeband, durations: Record<string, string> }>());

export const clearActiveReturnTripTimeband = createAction('[App] Clear active return trip timeband');

export const setActiveTripPrice = createAction('[App] Set active trip price', props<{ price: Price }>());

export const clearActiveTripPrice = createAction('[App] Clear active trip price');

export const setActiveReturnTripPrice = createAction('[App] Set active return trip price', props<{ price: Price }>());

export const clearActiveReturnTripPrice = createAction('[App] Clear active return trip price');

export const setActiveTripEmail = createAction('[App] Set active trip email', props<{ email: string }>());

export const clearActiveTripEmail = createAction('[App] Clear active trip email');

export const setActiveTripType = createAction('[App] Set active trip type', props<{ data: TripTypeEnum }>());

export const clearActiveTripType = createAction('[App] Clear active trip type');

export const setActiveTripAssistance = createAction('[App] Set active trip assistance', props<{ isAssistance: boolean }>());

export const clearActiveTripAssistance = createAction('[App] Clear active trip assistance');

export const setCheckoutCompleted = createAction('[App] Set checkout completed', props<{ completed: boolean }>());

export const resetAllTripData = createAction('[App] Reset all trip data');
