import { AppState, selectBookingState } from "./store.state";

import { createSelector } from "@ngrx/store";

export const selectActiveTrips = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripCalendarTemplate
);

export const selectActiveTripsData = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData
);

export const selectStations = createSelector(
    selectBookingState,
    (state: AppState) => state.stations
);

export const selectSession = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripCalendarTemplate?.session
);

export const selectActiveEmail = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData?.email
);

export const selectActiveType = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData?.type
);

export const selectActiveTripsDataDate = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData?.date
);

export const selectActiveTripsDataReturnDate = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData?.returnDate
);
