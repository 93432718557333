import { setActiveTripData, setActiveTripTemplate, setStationsList } from '@core/store/store.actions';

import { AppState } from '@core/store/store.state';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

const APP_PREFIX = 'SEATFROG-APP-';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private static readonly STATIONS = 'STATIONS';
  private static readonly ACTIVE_TRIP_TEMPLATE = 'ACTIVE_TRIP_TEMPLATE';
  private static readonly ACTIVE_TRIP_DATA = 'ACTIVE_TRIP_DATA';

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) { }

  dispatchInitialStoreActions() {
    const stations = this.getItem(LocalStorageService.STATIONS);
    if (stations) {
      this.store.dispatch(setStationsList(stations));
    }
    const activeTripTemplate = this.getItem(LocalStorageService.ACTIVE_TRIP_TEMPLATE);
    if (activeTripTemplate) {
      this.store.dispatch(setActiveTripTemplate({activeTrip: activeTripTemplate}));
    }
    const activeTripData = this.getItem(LocalStorageService.ACTIVE_TRIP_DATA);
    if (activeTripData) {
      this.store.dispatch(setActiveTripData({tripData: activeTripData}));
    }
  }

  setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    const item = localStorage.getItem(`${APP_PREFIX}${key}`)
    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        console.error(error);
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }

  removeActiveTrip() {
    this.removeItem(LocalStorageService.ACTIVE_TRIP_TEMPLATE);
    this.removeItem(LocalStorageService.ACTIVE_TRIP_DATA);
  }
}
