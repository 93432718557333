import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
    <app-container></app-container>
    <app-svg-definitions></app-svg-definitions>
  `
})
export class AppComponent implements OnInit {
  title = 'SEATFROG';

  constructor(
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.localStorageService.dispatchInitialStoreActions();
  }
}
