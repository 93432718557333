<div class="calendar-banner calendar-banner__template">
  <app-svg-icon icon="white-info" size="large"></app-svg-icon>
  <p>You can book your Secret Fare ticket from when services open for reservation (usually around 5 months before) up until {{ minDaysInFuture }} days before the journey.</p>
</div>

<div class="calendar-header">
  <button type="button"
          class="calendar-header__pagination__button calendar-header__pagination__button__previous"
          [disabled]="!previousEnabled()"
          (click)="previousClicked()">
    <app-svg-icon icon="chevron-left" size="xsmall"></app-svg-icon>
  </button>

  <span class="calendar-header__label">{{ periodLabel }}</span>

  <button type="button"
          class="calendar-header__pagination__button calendar-header__pagination__button__next"
          [disabled]="!nextEnabled()"
          (click)="nextClicked()">
    <app-svg-icon icon="chevron-right" size="xsmall"></app-svg-icon>
  </button>
</div>
