import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfigService } from "@core/services/config.service";

@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
    private configService: ConfigService,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
  }

  get minDaysInFuture() {
    return this.configService.minDaysInFuture;
  }

  // @see https://github.com/angular/components/blob/main/src/material/datepicker/calendar.ts#L154
  previousEnabled(): boolean {
    if (!this._calendar.minDate) {
      return true;
    }
    return (
      !this._calendar.minDate || !this._isSameView(this._calendar.activeDate, this._calendar.minDate)
    );
  }

  // @see https://github.com/angular/components/blob/main/src/material/datepicker/calendar.ts#L164
  nextEnabled(): boolean {
    return (
      !this._calendar.maxDate || !this._isSameView(this._calendar.activeDate, this._calendar.maxDate)
    );
  }

  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }

  // @see https://github.com/angular/components/blob/main/src/material/datepicker/calendar.ts#L171
  private _isSameView(date1: D, date2: D): boolean {
    return (
      this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) &&
      this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2)
    );
  }

  /**
   * A little bit of a hack to "move" the information banner from the top to the bottom of the calendar popover
   */
  ngAfterViewInit() {
    const calendarContainer = document.querySelector(".mat-calendar")!;

    const calendarBanner = document.querySelector(".calendar-banner__template")!;
    calendarContainer.appendChild(calendarBanner);

    const bannerToShow = document.querySelector(".mat-calendar > .calendar-banner__template")!;
    bannerToShow.classList.remove("calendar-banner__template");
  }
}
