import { Component } from '@angular/core';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';

SwiperCore.use([Pagination]);
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  config: SwiperOptions = {
    pagination: {
      clickable: true,
      el: '.slider-component__slide-wrapper--content__pagination',
      renderBullet: function (index, className) {
          return '<button class="' + className + '">' + "</button>";
        }
      },
    allowTouchMove: true,
  }
}
