<div class="slider" style="display: none;">
    <div class="slider-component wrapper">
        <swiper [config]="config">
            <ng-template swiperSlide>
                <div class="slider-component__slide-wrapper">
                    <div class="slider-component__slide-wrapper--image">
                        <img class="accent" src="assets/images/exploding-star.svg" alt="exploding star">
                    </div>
                    <div class="slider-component__slide-wrapper--content">
                        <img src="assets/images/quote.svg" alt="quote">
                        <div class="slider-component__slide-wrapper--content__description">
                            Nunc, sed dui, aliquet amet gravida amet. Venenatis adipiscing urna sed quam eu nisi.
                            Lobortis et ac Secret Fare netus congue consequat potenti.
                            Neque, adipiscing maecenas quam leo vitae pulvinar volutpat elementum.
                            Flex dignissim nunc pellentesque at aliquam mattis.
                        </div>
                        <div class="slider-component__slide-wrapper--content__author">
                            <div class="slider-component__slide-wrapper--content__author--name">Charlotte Rutherford</div>
                            <div class="slider-component__slide-wrapper--content__author--place">Surrey, UK</div>
                        </div>
                        <div class="slider-component__slide-wrapper--content__pagination">
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template swiperSlide>
                <div class="slider-component__slide-wrapper">
                    <div class="slider-component__slide-wrapper--image">
                        <img class="accent" src="assets/images/exploding-star.svg" alt="exploding star">
                    </div>
                    <div class="slider-component__slide-wrapper--content">
                        <img src="assets/images/quote.svg" alt="quote">
                        <div class="slider-component__slide-wrapper--content__description">
                            Nunc, sed dui, aliquet amet gravida amet. Venenatis adipiscing urna sed quam eu nisi.
                            Lobortis et ac Secret Fare netus congue consequat potenti.
                            Neque, adipiscing maecenas quam leo vitae pulvinar volutpat elementum.
                            Flex dignissim nunc pellentesque at aliquam mattis.
                        </div>
                        <div class="slider-component__slide-wrapper--content__author">
                            <div class="slider-component__slide-wrapper--content__author--name">Charlotte Rutherford</div>
                            <div class="slider-component__slide-wrapper--content__author--place">Surrey, UK</div>
                        </div>
                        <div class="slider-component__slide-wrapper--content__pagination">
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template swiperSlide>
                <div class="slider-component__slide-wrapper">
                    <div class="slider-component__slide-wrapper--image">
                        <img class="accent" src="assets/images/exploding-star.svg" alt="exploding star">
                    </div>
                    <div class="slider-component__slide-wrapper--content">
                        <img src="assets/images/quote.svg" alt="quote">
                        <div class="slider-component__slide-wrapper--content__description">
                            Nunc, sed dui, aliquet amet gravida amet. Venenatis adipiscing urna sed quam eu nisi.
                            Lobortis et ac Secret Fare netus congue consequat potenti.
                            Neque, adipiscing maecenas quam leo vitae pulvinar volutpat elementum.
                            Flex dignissim nunc pellentesque at aliquam mattis.
                        </div>
                        <div class="slider-component__slide-wrapper--content__author">
                            <div class="slider-component__slide-wrapper--content__author--name">Charlotte Rutherford</div>
                            <div class="slider-component__slide-wrapper--content__author--place">Surrey, UK</div>
                        </div>
                        <div class="slider-component__slide-wrapper--content__pagination">
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
</div>
