export function getTrackingParamsAsQueryString(): string {
    const queryParams = new URLSearchParams(window.location.search);

    const queryStringParts: string[] = [];

    for (const [key, value] of queryParams.entries()) {
        if (key.startsWith("utm_") || key === "channel" || key === "user_id") {
            queryStringParts.push(`${key}=${encodeURIComponent(value)}`);
        }
    }

    const gaClientId = getGaClientId();
    if (gaClientId) {
        queryStringParts.push(`ga_client_id=${encodeURIComponent(gaClientId)}`);
    }

    return queryStringParts.join("&");
}

function getGaClientId(): string | null {
    return getCookie("_ga")?.substring(6) ?? null;
}

function getCookie(name: string): string | null {
    const allCookies = decodeURIComponent(document.cookie).split(';');

    for (const c of allCookies) {
        let cookie = c;

        // ensure we clean up any leading spaces
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }

        // return the value of the cookie only
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
        }
    }

    return null;
}
