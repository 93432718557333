import { Component } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  constructor(
    private configService: ConfigService,
  ) { }

  faqElements = [
    {
      title: `How long before my train departing will I know what train I'm on?`,
      description: 'We’ll email your ticket to you 24 hours before departure.',
      isOpen: false,
    },
    {
      title: 'Can I get a physical ticket rather than an e-ticket?',
      description: 'No, you will only get your ticket emailed to you.',
      isOpen: false,
    },
    {
      title: 'Is my ticket refundable?',
      description: 'Secret Fare tickets are non-refundable.',
      isOpen: false,
    },
    {
      title: 'How soon before travelling can I book my ticket?',
      description: `You can book your Secret Fare ticket from when services open for reservation (usually around 5 months before) up until ${this.configService.minDaysInFuture} days before the journey.`,
      isOpen: false,
    },
    {
      title: 'How can I ensure that my group is seated together when travelling?',
      description: 'If you want to travel together, you must book multiple passengers at the same time. However, we can’t promise that all passengers in the same booking will be seated together as this is subject to availability.',
      isOpen: false,
    },
  ]

  toggleItem(index: number) {
    this.faqElements[index].isOpen = !this.faqElements[index].isOpen
  }

  goToHelp() {
    const url = 'https://help.seatfrog.com/hc/en-gb/categories/6486438890639-Secret-Fare';
    window.open(url, '_blank');
  }
}
