<header>
    <div class="header-component wrapper">
        <div class="header-component__links">
            <div class="header-component__links__left">
                <a href="https://seatfrog.com?ref=secretfare" alt="Seatfrog website">
                    <img routerLink="/" src="assets/images/Seatfrog-logo.svg" alt="">
                </a>
                <div class="header-component__links__left--items">
                    <a (click)="navigateHome()">Secret Fare</a>
                    <a (click)="navigateToHowItWorks()">How it works</a>
                    <a href="https://trainswap.seatfrog.com" target="_blank" rel="noopener noreferrer" aria-label="Train Swap site (opens in a new tab)">Train Swap</a>
                    <a href="https://sustainability.seatfrog.com" target="_blank" rel="noopener noreferrer" aria-label="Sustainability site (opens in a new tab)">Sustainability</a>
                    <a href="https://help.seatfrog.com/hc/en-gb/categories/6486438890639-Secret-Fare" target="_blank" rel="noopener noreferrer" aria-label="Help Centre for Secret Fare (opens in a new tab)">Help centre</a>
                </div>
            </div>
        </div>
    </div>
</header>
