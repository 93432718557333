<div class="newsletter">
    <div class="newsletter-component wrapper">
        <div class="newsletter-component__description-section">
            <h2>Newsletter? Yeah? Sweeeet.</h2>
            <p>Travel news, product updates, special offers, ridiculous deals on train tickets, and other cool stuff.</p>
        </div>
        <div class="newsletter-component__action-section" [formGroup]="newsletterForm">
            <input
                class="newsletter-component__action-section__form__input"
                formControlName="email"
                type="email"
                name="email"
                placeholder="Email address"
            >
            <button class="newsletter-component__action-section__form__button" (click)="subscribe()">Let’s do this</button>
        </div>
    </div>
</div>
