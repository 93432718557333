import { MomentDateAdapter } from "@angular/material-moment-adapter";

export class ThreeLetterDateAdapter extends MomentDateAdapter {
  /**
   * Use three letter abbreviations for day names instead of 2
   */
  getDayOfWeekNames(): string[] {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}
