import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { getStationsList, getStationsListFailure, getStationsListSuccess, initCheckoutSession, initCheckoutSessionFailure, initCheckoutSessionSuccess, searchAvailableTrips, searchAvailableTripsFailure, searchAvailableTripsSuccess, searchAvailableTripsWithReturn, searchAvailableTripsWithReturnFailure, searchAvailableTripsWithReturnSuccess, setActiveTripData } from "./store.actions";

import { ApiService } from "@core/services/api.service";
import { AppState } from "./store.state";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { MessageService } from "@core/services/message.service";
import { Router } from "@angular/router";
import { RoutingEnum } from "@shared/enums/navigation.enum";
import { Store } from '@ngrx/store';

@Injectable()
export class StoreEffects {

  getStationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStationsList),
      switchMap(() => this.apiService.fetchStationsList()
        .pipe(
          map(res => res.stations
            ? getStationsListSuccess({ data: res.stations })
            : getStationsListFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  searchAvailableTrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTrips),
      switchMap(({date, origin, destination, numberOfTravellers, tripData}) => this.apiService.searchForDepartures(date, origin, destination, numberOfTravellers)
        .pipe(
          map(res => res.outbound.days.length > 0 && res.bands.length > 0
            ? searchAvailableTripsSuccess({ data: res, tripData })
            : searchAvailableTripsFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  searchAvailableTripsWithReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTripsWithReturn),
      switchMap(({date, origin, destination, numberOfTravellers, inboundDate, tripData}) => this.apiService.searchForDeparturesWithReturn(date, origin, destination, numberOfTravellers, inboundDate)
        .pipe(
          map(res => res.outbound.days.length > 0 && res.inbound.days.length > 0 && res.bands.length > 0
            ? searchAvailableTripsWithReturnSuccess({ data: res, tripData })
            : searchAvailableTripsWithReturnFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  initCheckoutSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initCheckoutSession),
      switchMap(({ data }) => this.apiService.initCheckoutSession(data?.session, data?.request)
        .pipe(
          map(res => res
            ? initCheckoutSessionSuccess({ data: res })
            : initCheckoutSessionFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  getStationsListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStationsListSuccess),
      tap((action) => this.localStorageService.setItem("STATIONS", action.data)),
    ),
    { dispatch: false },
  );

  searchAvailableTripsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTripsSuccess),
      tap((action) => this.localStorageService.setItem("ACTIVE_TRIP_TEMPLATE", action.data)),
      tap(() => this.router.navigate([RoutingEnum.Booking])),
    ),
    { dispatch: false },
  );

  searchAvailableTripsWithReturnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTripsWithReturnSuccess),
      tap((action) => this.localStorageService.setItem("ACTIVE_TRIP_TEMPLATE", action.data)),
      tap(() => this.router.navigate([RoutingEnum.Booking])),
    ),
    { dispatch: false },
  );

  setActiveTripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveTripData),
      tap((action) => this.localStorageService.setItem("ACTIVE_TRIP_DATA", action.tripData)),
    ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
  ) { }
}
