<div class="cookie-consent" *ngIf="showCookieConsentBanner">
  <div class="cookie-consent__container">
    <h1 class="cookie-consent__title">🍪 We use cookies. You ok with that?</h1>
    <div class="cookie-consent__content">
      <div class="cookie-consent__message">
        <p>We use cookies to track stats. Not in an evil way. Just to keep things moving (it’s in our <a href="https://seatfrog.com/privacy/?ref=trainswap">privacy policy</a>).</p>
      </div>
      <div class="cookie-consent__buttons">
        <button (click)="declineNonEssentialCookies()" aria-label="Decline non-essential cookies">Just essential cookies</button>
        <button (click)="allowNonEssentialCookies()" aria-label="Allow non-essential cookies">Yep, cool</button>
      </div>
    </div>
  </div>
</div>