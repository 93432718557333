import * as Components from './components';
import * as Directives from './directives';
import * as Layouts from './layout';
import * as Pipes from './pipes';

import { CommonModule, DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RouterModule } from '@angular/router';
import { SwiperModule } from "swiper/angular";
import { ThreeLetterDateAdapter } from "@core/adapters/three-letter-calendar-adapter";
import { ToastrModule } from 'ngx-toastr';

const LayoutsList = [
  Layouts.ContainerComponent,
  Layouts.HeaderComponent,
  Layouts.MainComponent,
  Layouts.FooterComponent,
];

const ComponentsList = [
  Components.SpinnerComponent,
  Components.SvgIconComponent,
  Components.SvgDefinitionsComponent,
  Components.AccordionComponent,
  Components.CalendarHeaderComponent,
  Components.SearchTripFormComponent,
  Components.NewsletterComponent,
  Components.SliderComponent,
  Components.FaqComponent,
  Components.AssistanceDialogComponent,
  Components.CookieConsentBannerComponent,
];

const PipesList = [
  Pipes.StationNamePipe,
  Pipes.ActiveDatePipe,
];

const DirectivesList = [
  Directives.LazyImgDirective
]

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd DD MMM',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthLabel: 'MMMM',
  },
};

@NgModule({
  declarations: [
    ...LayoutsList,
    ...ComponentsList,
    ...PipesList,
    ...DirectivesList,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        RouterModule,
        ToastrModule.forRoot({
            positionClass: 'mg-toast',
            newestOnTop: true,
            preventDuplicates: true,
            maxOpened: 4,
        }),
        SwiperModule,
        MatAutocompleteModule,
    ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    ...LayoutsList,
    ...ComponentsList,
    ...PipesList,
    ...DirectivesList,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    DatePipe,
    {provide: DateAdapter, useClass: ThreeLetterDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SharedModule { }
