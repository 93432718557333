import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-assistance-dialog',
  templateUrl: './assistance-dialog.component.html',
  styleUrls: ['./assistance-dialog.component.scss']
})
export class AssistanceDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AssistanceDialogComponent>,
  ) {}

  submit() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
