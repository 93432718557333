<div class="search-trip" [class.gradient]="isGradientBackground">
    <form class="search-trip__form" [class.wrapper]="!isMobile" [formGroup]="homeForm">
        <ng-container *ngIf="showForm; else editTripsTemplate">
            <div class="search-trip__form__header">
                <div class="search-trip__form__header__container">
                    <div class="search-trip__form__header__group">
                        <label class="search-trip__form__header__label">
                            <input type="radio" value="one-way" formControlName="isReturn">
                            <span
                                [class.active]="homeForm.controls.isReturn.value === 'one-way'  && isMobile"
                                [class.disable]="homeForm.controls.isReturn.value === 'return' && isMobile">
                                One Way
                            </span>
                        </label>
                        <label class="search-trip__form__header__label">
                            <input type="radio" value="return" formControlName="isReturn">
                            <span
                                [class.active]="homeForm.controls.isReturn.value === 'return' && isMobile"
                                [class.disable]="homeForm.controls.isReturn.value === 'one-way' && isMobile">
                                Return
                            </span>
                        </label>
                    </div>
                    <div class="search-trip__form__header__passenger">
                        <div class="search-trip__form__header__passenger__trigger" (click)="isPassengersOpen = !isPassengersOpen">
                            <svg class="search-trip__form__header__passenger__trigger__icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="search-trip__form__header__passenger__icon__content" d="M8.61673 16.9124L6.9209 15.2166L11.1376 10.9999L6.9209 6.78324L8.61673 5.0874L14.5292 10.9999L8.61673 16.9124Z" fill="#1440CC"/>
                            </svg>
                            <p class="search-trip__form__header__passenger__trigger__text">{{homeForm.controls.passengers.value}} passenger{{homeForm.controls.passengers.value > 1 ? 's' : ''}}</p>
                        </div>
                        <div class="search-trip__form__header__passenger__dropdown"
                            *ngIf="isPassengersOpen"
                            @fadeIn
                            @fadeOut>
                            <div class="search-trip__form__header__passenger__dropdown__container">
                                <span
                                    class="search-trip__form__header__passenger__dropdown__button"
                                    [class.disabled]="passengers.value === 1"
                                    (click)="decrease()">
                                    <svg class="search-trip__form__header__passenger__dropdown__icon" width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="search-trip__form__header__passenger__dropdown__icon" d="M0.333252 1.66659V0.333252H9.66659V1.66659H0.333252Z" fill="white"/>
                                    </svg>
                                </span>
                                <input
                                    class="search-trip__form__header__passenger__dropdown__input"
                                    name="passengers"
                                    type="number"
                                    formControlName="passengers"
                                    min="1"
                                    max="9"
                                    readonly/>
                                <span
                                    class="search-trip__form__header__passenger__dropdown__button"
                                    [class.disabled]="passengers.value === 9"
                                    (click)="increase()">
                                    <svg class="search-trip__form__header__passenger__dropdown__icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="search-trip__form__header__passenger__dropdown__icon" d="M4.33325 9.66659V5.66659H0.333252V4.33325H4.33325V0.333252H5.66659V4.33325H9.66659V5.66659H5.66659V9.66659H4.33325Z" fill="white"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isMobile || isTablet">
                    <label class="search-trip__form__checkbox">
                        <input class="search-trip__form__checkbox__input" formControlName="isAssistance" type="checkbox" data-toggle="toggle">
                        I need passenger assistance
                    </label>
                </div>
            </div>
            <div class="search-trip__form__body" @fadeIn
            @fadeOut>
                <div class="search-trip__form__body__container">
                    <div class="search-trip__form__input-container">
                        <label for="from">
                            Leaving from
                            <span *ngIf="homeForm.controls.leaving.value"
                                class="search-trip__form__input-clear"
                                (click)="resetOrigin(homeForm.controls.leaving, $event)">Clear</span>
                        </label>
                        <span *ngIf="isMobile && homeForm.controls.leaving.value"
                            class="search-trip__form__input-clear"
                            (click)="resetOrigin(homeForm.controls.leaving, $event)">Clear</span>
                        <div class="select-input">
                            <input #origin
                                id="from"
                                name="from"
                                type="text"
                                placeholder="Leaving from"
                                matInput
                                [formControl]="originInput"
                                [matAutocomplete]="originAuto"
                                matAutocompletePosition="below"
                                (input)="filterOrigins()"
                                (focus)="filterOrigins()"
                                (blur)="userManuallyTypedOrigin()">
                            <mat-autocomplete
                                #originAuto="matAutocomplete"
                                [displayWith]="mapToStationName"
                                (optionSelected)="changeOrigin($event)">
                                <mat-option *ngFor="let origin of filteredEligibleOrigins" [value]="origin">{{origin.name}}</mat-option>
                            </mat-autocomplete>
                        </div>
                        <ul *ngIf="isSubmittedForm && homeForm.controls.leaving.errors"
                            class="search-trip__form__error-msg"
                            @fadeIn
                            @fadeOut>
                            <li *ngIf="homeForm.controls.leaving.errors">Please select a station</li>
                        </ul>
                    </div>
                    <div class="search-trip__form__input-container">
                        <label for="to">
                            Going to
                            <span *ngIf="homeForm.controls.going.value"
                                class="search-trip__form__input-clear"
                                (click)="resetDestination(homeForm.controls.going, $event)">Clear</span>
                        </label>
                        <span *ngIf="isMobile && homeForm.controls.going.value"
                            class="search-trip__form__input-clear"
                            (click)="resetDestination(homeForm.controls.going, $event)">Clear</span>
                        <div class="select-input">
                            <input #destination
                                id="to"
                                name="to"
                                type="text"
                                placeholder="Going to"
                                matInput
                                [formControl]="destinationInput"
                                [matAutocomplete]="destinationAuto"
                                matAutocompletePosition="below"
                                (input)="filterDestinations()"
                                (focus)="filterDestinations()"
                                (blur)="userManuallyTypedDestination()">
                                <mat-autocomplete
                                    #destinationAuto="matAutocomplete"
                                    [displayWith]="mapToStationName"
                                    (optionSelected)="changeDestination($event)">
                                    <mat-option *ngFor="let destination of filteredEligibleDestinations" [value]="destination">{{destination.name}}</mat-option>
                                </mat-autocomplete>
                        </div>
                        <ul *ngIf="isSubmittedForm && homeForm.controls.going.errors"
                            class="search-trip__form__error-msg"
                            @fadeIn
                            @fadeOut>
                            <li *ngIf="homeForm.controls.going.errors">Please select a station</li>
                        </ul>
                    </div>
                </div>
                <ng-container *ngIf="!isMobile">
                    <div class="search-trip__form__input-container date">
                        <label for="depart">Depart</label>
                        <div class="search-trip__form__datepicker">
                            <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
                            <input
                                class="search-trip__form__datepicker__input"
                                name="depart"
                                matInput
                                [matDatepicker]="picker"
                                placeholder="Select date"
                                formControlName="depart"
                                yPosition="0"
                                [min]="minDate"
                                [max]="maxDate"
                                (click)="picker.open()"
                                readonly/>
                        </div>
                        <mat-datepicker [startAt]="minDate"  #picker [yPosition]="'below'" [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
                        <ul *ngIf="isSubmittedForm && homeForm.controls.depart.errors"
                            class="search-trip__form__error-msg"
                            @fadeIn
                            @fadeOut>
                            <li *ngIf="homeForm.controls.depart.errors">Please enter a date</li>
                        </ul>
                    </div>
                    <div class="search-trip__form__input-container date"
                        *ngIf="homeForm.controls.isReturn.value === 'return'"
                        @fadeIn
                        @fadeOut>
                        <label for="return">Return</label>
                        <div class="search-trip__form__datepicker">
                            <mat-datepicker-toggle matIconPrefix [for]="returnPicker"></mat-datepicker-toggle>
                            <input
                                class="search-trip__form__datepicker__input"
                                name="return"
                                matInput
                                [matDatepicker]="returnPicker"
                                placeholder="Add return"
                                formControlName="return"
                                [min]="homeForm.controls.depart.value ? homeForm.controls.depart.value : minDate"
                                [max]="maxDate"
                                (click)="returnPicker.open()"
                                readonly/>
                        </div>
                        <mat-datepicker [startAt]="homeForm.controls.depart.value ? homeForm.controls.depart.value : minDate"  #returnPicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
                        <ul *ngIf="isSubmittedForm && !homeForm.controls.return.value && homeForm.controls.isReturn.value === 'return'"
                            class="search-trip__form__error-msg"
                            @fadeIn
                            @fadeOut>
                            <li>Please enter a return date</li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngIf="isMobile">
                    <div class="search-trip__form__input-container--split date">
                        <div class="search-trip__form__input-container">
                            <label for="depart">Depart</label>
                            <div class="search-trip__form__datepicker">
                                <mat-datepicker-toggle matIconPrefix [for]="tabletDatepicker"></mat-datepicker-toggle>
                                <input
                                    class="search-trip__form__datepicker__input"
                                    name="depart"
                                    matInput
                                    [matDatepicker]="tabletDatepicker"
                                    placeholder="Select date"
                                    formControlName="depart"
                                    [min]="minDate"
                                    [max]="maxDate"
                                    (click)="tabletDatepicker.open()"
                                    readonly/>
                            </div>
                            <mat-datepicker [startAt]="minDate"  #tabletDatepicker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
                            <ul *ngIf="isSubmittedForm && homeForm.controls.depart.errors"
                                class="search-trip__form__error-msg"
                                @fadeIn
                                @fadeOut>
                                <li *ngIf="homeForm.controls.depart.errors">Please enter a date</li>
                            </ul>
                        </div>
                        <div class="search-trip__form__input-container date">
                            <label for="return">Return</label>
                            <div class="search-trip__form__datepicker">
                                <mat-datepicker-toggle matIconPrefix [for]="tabletReturnPicker"></mat-datepicker-toggle>
                                <input
                                    class="search-trip__form__datepicker__input"
                                    name="return"
                                    matInput
                                    [matDatepicker]="tabletReturnPicker"
                                    [placeholder]="homeForm.controls.isReturn.value === 'return' ? 'Add return' : '+ Add return'"
                                    formControlName="return"
                                    [min]="homeForm.controls.depart.value ? homeForm.controls.depart.value : minDate"
                                    [max]="maxDate"
                                    (click)="tabletReturnPicker.open()"
                                    readonly/>
                            </div>
                            <mat-datepicker [startAt]="homeForm.controls.depart.value ? homeForm.controls.depart.value : minDate"  #tabletReturnPicker (opened)="checkReturnState()" [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
                            <ul *ngIf="isSubmittedForm && !homeForm.controls.return.value && homeForm.controls.isReturn.value === 'return'"
                                class="search-trip__form__error-msg"
                                @fadeIn
                                @fadeOut>
                                <li>Please enter a return date</li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <div class="search-trip__form__assistance-mobile" *ngIf="isMobile && !isTablet">
                    <label>
                        <input formControlName="isAssistance" type="checkbox" data-toggle="toggle">
                        I need passenger assistance
                    </label>
                </div>
                <button
                    type="submit"
                    class="search-trip__form__submit"
                    (click)="submit()">
                    <p>{{ isUpdate && isMobile ? 'Update search' : 'Search'}}</p>
                </button>
            </div>
            <ng-container *ngIf="isLoading">
                <app-spinner [small]="true" color="#000000"></app-spinner>
            </ng-container>
        </ng-container>
        <ng-template #editTripsTemplate>
            <div class="edit-trip">
                <div class="edit-trip__container" @fadeOut (click)="showForm = !showForm">
                    <div class="edit-trip__search">
                        <app-svg-icon icon="search" size="large"></app-svg-icon>
                    </div>
                    <div class="edit-trip__info">
                        <div class="edit-trip__info--fline">
                            {{leavingStation.name}}
                            <app-svg-icon icon="arrow" size="small"></app-svg-icon>
                            {{goingStation.name}}
                        </div>
                        <div class="edit-trip__info--sline">
                            {{isReturn.value | titlecase}}<span>•</span>{{passengers.value}} passenger<span>•</span>{{depart.value | date: 'EEE d MMM'}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </form>
</div>
