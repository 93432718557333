export enum RoutingEnum {
  FAQ = 'faq',
  TermsAndConditions = 'terms-and-conditions',
  Booking = 'booking',
  Confirm = 'confirm',
  Purchase = 'purchase',
  ABetterFuture = 'https://seatfrog.com/a-better-future/',
  PartnerWithUs = 'https://seatfrog.com/partner-with-us/',
  TrainSwap = 'https://trainswap.seatfrog.com',
  Facebook = 'https://www.facebook.com/Seatfrog',
  Instagram = 'https://www.instagram.com/Seatfrog',
  Twitter = 'https://twitter.com/Seatfrog',
  WorkAtSeatfrog = 'https://seatfrog.com/workatseatfrog/',
  HelpCentre = 'https://help.seatfrog.com/hc/en-gb',
  SeatfrogHome = 'https://seatfrog.com?ref=secretfare'
}
