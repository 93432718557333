import { ReturnSearchResponse, SingleSearchResponse } from "@core/types/search";

import { Station } from "@core/types/stations";
import { TripData } from "@core/types/data";
import { createFeatureSelector } from "@ngrx/store";

export const selectBookingState = createFeatureSelector<AppState>('booking');

export interface AppState {
    stations: Station[],
    activeTripCalendarTemplate: SingleSearchResponse | ReturnSearchResponse | null,
    activeTripData: TripData | null,
    checkoutCompleted: boolean | null,
}
